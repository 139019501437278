body {
  width: auto;
  height: 100vh;
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(/images/homes1.jpg) center;
  background-size: cover;
  opacity: 0.91;
}

.headshot-image {
  border-radius: 5rem;
  width: 75px;
  height: 100%;
  position: relative;
  top: 24px;
  border: 1px solid black;
}

.logo-image {
  width: 100%;
  max-width: 282px;
  padding: 10px;
  border-radius: 10px;
}

.media-body.eddie {
  position: relative;
  top: 12px;
}

.media.eddie {
  position: absolute;
    float: left;
    background: rgb(255,255,255,0.8);
    padding: 0 40px;
    color: black;
    border-radius: 10px;
}

#addressInput, #addressDropdown {
  opacity: 0.9;
}

.card {
  background-color: transparent;
}

.form-control, .custom-select {
  background: rgba(0, 0, 0, 0.7);
  color: white;
}

.semi-dark-bg {
  background-color: rgba(0, 0, 0, 0.7);
}